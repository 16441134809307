@import url('../../assets/styles/variables.css');

.pagination {
  display: flex;
  list-style: none;
}

.pagination li {
  font-size: 12px;
  padding: 10px;
}

.pagination li a {
  cursor: pointer;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.active {
  background-color: none;
  font-weight: 700;
  color: var(--portal-primary-color);
}