@import url('../../../assets/styles/variables.css');

.homeContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  overflow: hidden;
  background: var(--portal-white-color);
}

.homeSize {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  min-height: 70vh;
  padding: 0 70px;
  max-width: 500px;  
  overflow: hidden;
}

.containerLimit {
  max-width: 245px;
}

.infoText {
  color: var(--secondary-color);
}

.layoutHeader {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-height: 50px;
  max-width: 200px;
  overflow: hidden;
  padding: 55px 0 0 0;  
}

.layoutLogoMobile {
  max-width: 130px;
}

.footer {
  width: 100%;
  min-height: 30vh;
  background-color: var(--portal-primary-color);
  background-image: url('../../../assets/images/homeimage.svg');
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 1100px) {
  .layoutHeader {
    max-height: 100px;
    max-width: 400px;
  }
  
  input {
    max-width: 470px;
  }
  
  .homeContainer input {
    min-width: 470px;
  }
  .containerLimit {
    max-width: 100%;
  }
}