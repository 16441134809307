@import url('../../../assets/styles/variables.css');

.wrapper {
  width: 100%;
}

.wrapper .legend {
  list-style: none;
  margin: 0;
  padding: 0;
}

.wrapper .legend li {
  font-size: 14px;
  color: var(--black-color);
  font-weight: normal;
  margin-bottom: 15px !important;
}

.wrapper .legend li .piePercentage {
  font-weight: 700;
  margin-right: 5px;
}

.wrapper .legend li .span {
  font-size: 13px;
}

.wrapper .topContent {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--portal-secondary-light-color);
}


.wrapper h1 {
  font-size: 20px;
  color: var(--portal-secondary-color);
  font-weight: 700;
}

.intro {
  color: #000;
  font-size: 15px;
  font-weight: 700px;
}

@media (min-width: 600px) {
  .wrapper {
    flex-direction: row;
    justify-content: space-between;
  }
}