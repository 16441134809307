@import url('../../assets/styles/variables.css');

.breadcrumb {
  display: flex;
  list-style: none;
  padding-left: 20px;
}

.breadcrumb li a {
  text-decoration: none;
  color: var(--portal-dark-gray-color);
  font-size: 15px;
  font-weight: 700;
}

.breadcrumb li a:after {  
  content: '>';
  font-weight: 700;
  color: var(--portal-dark-gray-color);
  margin: 0 10px;
}

.breadcrumb li span {
  font-size: 15px;
  font-weight: 500;
  color: var(--portal-dark-gray-color);
}