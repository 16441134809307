@import url('../../../assets/styles/variables.css');

.loginContainer {
  align-items: center;
  background: var(--gray-90-color);
  flex-direction: column;
  display: flex;
  height: 100vh;
  justify-content: center;
}

.cardForm {
  background: var(--white-color);
  border: 1px solid var(--athens-gray-color);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 40px;
  width: 380px;
  height: 500px;
  padding-bottom: 10px;
}

.formGroup {
  margin: 0;
}

.formItem>* {
  display: flex;
}

label {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #9FA2B4;
  width: 100%;
}

input {
  margin-top: 5px;
  background: #FCFDFE;
  border: 1px solid #F0F1F7;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 11px;
  width: 100%;
}

.cardFormHeader {
  text-align: center;
}

.cardFormHeader img {
  max-width: 215px;
}

.textInfo {
  font-size: 20px;
  color: #11183F;
}

.btnSubmit {
  width: 100%;
  border: none;
  background: var(--indigo-color);
  box-shadow: 0px 4px 12px rgba(86, 104, 209, 0.5);
  border-radius: 8px;
  color: #fff;
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  padding: 10px 24px;
  animation: all ease-in-out .5s;
  cursor: pointer;
}

.btnSubmit:hover {
  opacity: 0.9;
}

.errorMessage {
  color: #f04c4c;
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
}

.lockMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  color: var(--success-color);
  text-align: center;
  padding: 10px 0;
}

.lockMessage i {
  margin-right: 5px;
}

.lockMessage i  svg {
  width: 20px;
  height: 20px;
}

.passContainer {
  display: flex;
  align-items: center;
}

.passType {
  background: none;
  border: none;
  transform: translate(-10px, 53px);
  cursor: pointer;
  z-index: 5;
  line-height: 0; 
  margin-left: auto;
  margin-right: 0;
}

.passType svg {
  margin-top: 5px;
  width: 15px;
  height: 15px;
  fill: var(--waterloo-color);
}

.inputsContainer {
  margin-bottom: 45px;
}