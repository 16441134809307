@import url('../../../assets/styles/variables.css');

.offerContainer {
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
}

.top {
  display: flex;
  background-color: var(--portal-secondary-color);
  width: 100%;
  padding: 0 40px 0 20px;
  min-height: 260px;
}

.top > div {
  width: 50%;
}

.topContent {
  transform: translateX(-20px);
  width: calc(50% - 15px);
  margin: 110px 0 0 15px;
}

.topContent h1:last-child {
  font-weight: normal;
  line-height: 22px;
}

.top .goBackButton {
  width: 20px;
  margin-top: 50px;
}

.customImage {
  background-image: url('../../../assets/images/ellipse3.svg');
  background-repeat: no-repeat;
  background-position: center;
  min-width: 100px;
  min-height: 260px;
}

.offerCard {
  margin-top: 40px;
  background-color: var(--white-color);
  padding: 0 35px;
}

.offerCard h1 {
  display: flex;
  width: 100%;
}

.offerInfo {
  display: flex;
  justify-content: space-between;
}

.offerInfo > div h1 {
  color: var(--portal-secondary-color);
}

.offerInfo img {
  width: 150px;
}

.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.container .left {
  font-family: var(--primary-font) !important;  
  width: 65%;
  color: var(--secondary-primary-color);
}

.container .left span {
  font-family: var(--primary-font) !important;  
  color: var(--secondary-primary-color);
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
}

.container .right {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 35%;
}

.container .right span {  
  color: var(--secondary-primary-color);
  font-family: var(--primary-font);
  font-size: 15px;
  font-weight: 700;
  text-align: right;
}


.offerInfoCount {
  background-color: var(--athens-gray-color);
  border-radius: 15px;
  padding: 5px;
}


.amountDebt {
  color: var(--red-color);
  font-weight: bold;
}

.amountDebtDiscount {
  color: var(--dark-green);
  font-weight: bold;
}

.offerCard > * {
  margin-bottom: 30px;
}

.countdown {
  margin: 0 auto;
  color: var(--portal-gray-color);
  text-align: center;
  font-size: 15px;
  line-height: 25px;
  max-width: 280px;
}

.offerCard label:last-of-type > div {
  border: none;
}

@media only screen and (min-width: 1100px) {
  .offerContainer {
    max-width: 100%;
  }
}
