@import url('../../assets/styles/variables.css');

.wrapper {
  height: 90px;
  background: var(--portal-primary-color);
  padding: 30px 20px 0 35px;
}

.wrapper h3 {
  color: var(--white-color);
  font-size: 20px;
  font-weight: 700;
  font-family: var(--primary-font);
  margin: 0 0 10px 0;
}

.wrapper h4 {
  color: var(--white-color);
  font-size: 12px;
  font-weight: 500;
  font-family: var(--primary-font);
  margin: 0;
}