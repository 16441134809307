@import url('../../assets/styles/variables.css');

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.item {
  display: flex;
  align-items: flex-start;
  padding: 20px;
  background-color: var(--white-color);
  cursor: pointer;
  color: var(--portal-dark-gray-color);
  font-size: 12px;
  font-weight: 500;
}

.item.itemArrow {
  justify-content: flex-start;
}

.item.main {
  align-items: center;
  background-color: #F2F5FB;
  cursor: default;  
  justify-content: space-between;
}

.item.main .contentItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item.main span {
  color: var(--portal-dark-gray-color);
  font-size: 11px;
  margin-bottom: 10px;
}

.item.main b {
  color: var(--bunting-color);
  font-size: 11px;
  font-weight: 700;
}

i {
  margin-right: 10px;
}

i svg {
  width: 12px;
  height: 12px;
}