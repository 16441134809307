@import url('../../../assets/styles/variables.css');

.paymentContainer {
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
  color: var(--portal-secondary-color);
  
}

.paymentCode {
  background-color:var(--white-color);
  border-radius: 10px;
  color: var(--portal-secondary-color);
  font-size: 1.3em;
  height: 150px;
  overflow: hidden;
  padding: 20px;
  word-wrap: break-word;
  border: 1px solid var(--portal-gray-color);
  max-width: 225px;
  margin: 0 auto;
}


.top {
  display: flex;
  background-color: var(--portal-primary-color);
  width: 100%;
  padding: 0 40px;
  min-height: 220px;
}

.topContent {
  width: calc(55% - 15px);
  margin: 90px 0 0 15px;
}

.top .goBackButton {
  width: 20px;
  margin-top: 50px;
}

.customImage {
  width: 45%;
  background-image: url('../../../assets/images/boleto-barcode.svg');
  background-repeat: no-repeat;
  background-position: center;
  min-width: 100px;
  min-height: 220px;
}

.content {
  margin: 60px;
}

.content p {
  font-family: var(--primary-font);
  color: var(--portal-secondary-color);
  font-size: 15px;
  text-align: center;
  line-height: 25px;
  margin: 0 auto 50px auto;
}

@media only screen and (min-width: 1100px) {
  .paymentContainer {
    max-width: 100%;
  }
}
