@import url('../../../assets/styles/variables.css');

.titlePastDueDash,
.subtitlePastDueDash {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #757A96;
}

.titlePastDueDash {
  font-size: 24px;
  line-height: 30px;
}

.subtitlePastDueDash {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
}

.customerName {
  color: #11183F;
  font-weight: 600;
}

.uploadDocument {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #DFE0EB;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0;
}

.uploadDocument > div {
  flex: 1;
  margin: 0 20px;
}

@media screen and (max-width: 1420px) {
  .uploadDocumentContent {
    flex-direction: column-reverse;    
  }
}

.uploadDocumentTitle {
  text-align: center;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 0.9em;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #252733;
}

.uploadDocumentContent {
  display: flex;
  padding: 20px;
  background: var(--white-color);
  border-radius: 8px;
}

.top {
  height: 130px;
  background: var(--indigo-color);
  padding: 40px 20px 30px 60px;
}

.top h3 {
  color: var(--white-color);
  font-size: 36px;
  font-family: var(--primary-font);
}

.subtitlePastDueDashCustomer {
  font-size: 30px;
  font-weight: bold;
  color: var(--indigo-color);
  margin: 0;
}

.infoCustomer h5 {
  font-size: 16px;
  color: var(--bunting-color);
  margin-top: 10px;
  font-weight: 500;
}


h5 a {
  font-size: 16px;
  color: var(--indigo-color);
  text-decoration: none;
}

.paddingContent {
  padding: 40px 40px 40px 60px; 
}

.smallMargin {
  margin-bottom: 20px;
}

.imageContainer {
  width: 33.33%;
}

.imageContainer img {
  width: 350px;
  height: 300px;
}