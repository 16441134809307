@import url('../../assets/styles/variables.css');

.wrapper {
  width: 50px;
  height: 50px;
  background: var(--portal-dark-gray-color-secondary);
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: background 0.3s ease;
}

.wrapper:hover {
  background: var(--primary-color);
}

.wrapper svg {
  width: 27px;
  height: 27px;
}

.wrapper svg path {  
  color: var(--white-color);
}


