@import url('../../../assets/styles/variables.css');

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.wrapper .topContent {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--portal-secondary-light-color);
}

.wrapper h1 {
  font-size: 20px;
  color: var(--portal-secondary-color);
  font-weight: 700;
}

.wrapper .tooltip {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 46px;
  background-color: var(--portal-secondary-color);
  border-radius: 12px;
}

.wrapper .tooltip p {
  color: var(--white-color);
  font-size: 11px;
  font-weight: 600;
}

.wrapper .legend {
  display: flex;
  align-items: center;
  margin-right: 15px;
  font-size: 10px;
  font-weight: normal;
  color: var(--black-color);
}

.wrapper .tickText {
  font-size: 12px;
  color: var(--portal-dark-gray-color);
}