@import url('../../assets/styles/variables.css');

.timeline {
  display: flex;
  flex-direction: column;
  margin: 30px 0 0 30px;
}

.timelineEvent {
  position: relative;
  padding: 0 35px 35px 35px;
  border-left: 2px solid #ccc;
}

.timelineEvent:first-child div {
  transform: translateY(-5px);
}

.timelineEvent:not(first-child) div {
  transform: translateY(2px);
}

.timelineEvent:before {
  content: "";
  position: absolute;
  top: 8px;
  left: -7px;
  width: 12px;
  height: 12px;
  border: 1px solid #b9b9b9;
  border-radius: 50%;
  background-color: #fff;
}

.timelineEvent:first-child:before {
  content: "";
  position: absolute;
  top: 6px;
  left: -1px;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  border: 1px solid #b9b9b9;
  border-radius: 50%;
  background-color: #fff;
}

.timelineEvent:first-child::after {
  content: "";
  position: absolute;
  top: 6px;
  left: -1px;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--primary-color);
}

.timelineEvent:not(:first-child) .date, .timelineEvent:not(:first-child) .description {
  color: var(--portal-dark-gray-color);
}

.timelineEvent:last-child {
  margin-bottom: 0;
}

.date {
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 10px;
  color: var(--dash-sidebar-secondary);
}

.description {
  color: var(--portal-dark-gray-color);
  font-size: 15px;
}
