@import url('../../assets/styles/variables.css');
:root {
  --card-line-height: 1.2em;
  --card-padding: 1em;
  --radio-border-width: 2px;
  --radio-size: 1.5em;
}

label {
  width: 100%;
}

.cardInputElement {
  display: none;
}

.cardInput {
  display: flex;
  align-items: center;
  margin: 10px 0;
  padding: 10px 0 30px 0;
  box-sizing: border-box;
  border-bottom: 1px solid var(--portal-gray-color);
}

.cardInput:hover {
  cursor: pointer;
}

.cardInputElement:checked + .cardInput {
  border-color: #13CAE6;
}

.radioCircle {
  height: 20px;
  width: 20px;
  background: #fff;
  border-radius: 50%;
  border: 2px solid var(--portal-primary-color);
  padding: 2px;
}

.radioDot {
  content: "";
  height: 20px;
  width: 20px;
  background: #fff;
  border-radius: 50%;
}

.cardInputElement:checked + .cardInput {
  border-color: var(--portal-primary-color);
}

.cardInputElement:checked + .cardInput > .radioCircle > .radioDot {
  background: var(--portal-primary-color);
}

.contentRadio {
  display: flex;
  width: 100%;
  padding-left: 20px;
}