@import url('../../../assets/styles/variables.css');

.managerContainer {
  background-color: #eeeeee;
  min-height: 100vh;
  padding: 20px;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #757a96;
  font-size: 14px;
}

.headerContent {
  display: flex;
  justify-content: space-between;
}

.title {
  font-weight: normal;
}

.availableCredit {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  justify-content: center;

  background-color: #eef2f6;
  padding: 0px 5px;
  border-radius: 6px;
  border: 1px solid lightgray;
}

.availableCredit span {
  font-weight: bold;
  color: #757a96;
}

.availableCredit p,
span {
  margin: 0;
  padding: 0;
}

.generalInfos {
  display: flex;
  flex-direction: column;

  text-align: justify;
}

.generalInfos p:first-child span {
  font-weight: bold;
}

.generalInfos p:not(:first-child) span {
  color: #11183f;
  cursor: pointer;
}

.steps {
  display: grid;
  grid-template-columns: 35% 60%;
  grid-gap: 10% 5%;
}

.areaUpload {
  background-color: #eef2f6;
  border-radius: 6px;
  display: flex;
  padding-bottom: 30px;
}

.leftSection {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #11183f;
}

.leftSection img {
  height: 104px;
}

.analyticsFilter {
  background-color: #eef2f6;
  padding: 5px 20px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}

.headerFilters {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.headerFilters h2 {
  font-weight: 700;
  color: #11183f;
}

.headerFilters select {
  width: 100%;
}

.selectedFilters {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.selectedFilters p {
  margin: 0;
  padding: 0;
  color: #11183f;
  font-size: 17px;
  margin-bottom: 15px;
  font-weight: 700;
}

.cardSelectedFilters {
  width: 100%;
  background-color: #ffffff;
  margin-bottom: 15px;
  height: 160px;
  overflow: auto;
  border-radius: 8px;
  padding: 5px 10px;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cardSelectedFilters span:last-child {
  cursor: pointer;
}

.cardSelectedFilters div {
  display: flex;
  background-color: #dfe0eb;
  padding: 3px 10px;
  border-radius: 6px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  color: #11183f;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.cardSelectedFilters::-webkit-scrollbar,
.historySection::-webkit-scrollbar {
  width: 12px;
  border-radius: 8px;
}

.cardSelectedFilters::-webkit-scrollbar-track,
.historySection::-webkit-scrollbar-track {
  background: #dfe0eb;
  border-radius: 8px;
  padding-left: 50px;
}

.cardSelectedFilters::-webkit-scrollbar-thumb,
.historySection::-webkit-scrollbar-thumb {
  background-color: #9fa2b4;
  border-radius: 20px;
  border: 3px solid transparent;
}

.btnSubmit {
  border: none;
  background: #11183f;
  box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
  border-radius: 8px;
  color: #fff;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 0.7em;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.2px;
  padding: 5px 8px;
  animation: all ease-in-out 0.5s;
  cursor: pointer;
}

.btnSubmit svg {
  width: 20px;
  height: 20px;
}

.btnSubmit:hover {
  opacity: 0.9;
}

.selectWrapper {
  width: 190px;
  height: 35px;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  background-color: #F5F5F5;
  margin-right: 10px;
}

.selectWrapper::after {
  content: "\25BC";
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 0;
  padding: 0;
  background: #F5F5F5;
  pointer-events: none;
  transition: 0.25s all ease;
  color: #B9B9B9;
  font-size: 12px;
  transition: transform 0.25s ease;
}

.selectWrapper.active::after {
  content: "\25B2";
}

.selectWrapper:hover::after {
  color: #B9B9B9;
}

.selectWrapper select.input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  outline: 0;
  border: none;
  background-color: transparent;
  color: #B9B9B9;
  overflow: auto;
  border-radius: 5px;
  padding: 5px 18px;
  height: 35px;
}

.selectWrapper select.input::-ms-expand {
  display: none;
}

.selectWrapper select.input option {
  display: block;
  padding: 20px 18px;
}

.historyContainer {
  background: #eef2f6;
  border-radius: 8px;
  padding: 20px;
  margin-top: 50px;
}

.historyContainer h2 {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;

  line-height: 21px;
  color: #11183f;
  margin: 0 30px 30px 30px;
}

.historySection {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  gap: 30px;
  overflow: auto;
  max-height: 220px;
}

.historyContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.historyContent:hover {
  text-decoration: underline;
  cursor: pointer;
}

.historyDetails {
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-right: 5px;
}

.modalContainer {
  height: 200px;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-size: 18px;
  text-align: center;
}

.modalContainer p {
  margin-bottom: 40px;

  color: #11183f;
}

.modalContainer span {
  font-weight: bold;
}

.modalContainer img {
  height: 70px;
}

.containerWithTitle {
  background: #eef2f6;
  border-radius: 8px;
  padding: 30px;
  margin-bottom: 43px;
}

.containerWithTitle h2 {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;

  line-height: 21px;
  color: #11183f;
  margin-top: 0;
}

.containerWithTitle form {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.containerWithTitle input.input {
  margin-bottom: 0;
}

.inputGroup {
  display: flex;
  gap: 15px;
  align-items: center;
}


.inputGroup input, .inputGroup select {
  margin: 0;
}

.inputGroup button {
  border: none;
  background: #11183f;
  box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
  border-radius: 8px;
  color: #fff;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5em;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.2px;
  padding: 5px 12px;
  animation: all ease-in-out 0.5s;
  cursor: pointer;
  height: 38px;
}


.titlePastDueDash,
.subtitlePastDueDash {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #757A96;
}

.titlePastDueDash {
  font-size: 24px;
  line-height: 30px;
}

.subtitlePastDueDash {
  font-family: 'Quicksand';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
}

.customerName {
  color: #11183F;
  font-weight: 600;
}

.card, .cardGreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 130px;

  background: #FFFFFF;
  border: 1px solid #DFE0EB;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  padding: 20px;
  text-align: center;
  margin: 5px;
}

.card > *, .cardGreen > * {
  margin: 0;
  padding: 0;
  font-weight: 500;
}

.contentFlex {
  display: flex;
  justify-content: space-around;
}

.contentFlex1 {
  display: flex;
  justify-content: space-around;
}

.contentFlex1 > .infoCustomer {
 width: calc(100% - 120px);
}

.contentFlex1 > .card {
  width: calc(100vw / 3);
 }
 

.amountTotal {
  font-size: 30px;
  padding-top: 5px;
  color: #11183F;
}

.amountTotalGreen {
  padding-top: 5px;
  color: #03942C;
}

.infoCustomer {
  box-sizing: border-box;
  padding: 10px;
}

.containerCards {
  display: flex;
}

.top {
  height: 90px;
  background: var(--portal-primary-color);
  padding: 30px 20px 0 65px;
}

.top h3 {
  color: var(--white-color);
  font-size: 36px;
  font-family: var(--primary-font);
  margin: 0;
}

.contentContainer {
  background: var(--white-color);
  padding: 30px 30px 0 20px;
  border-radius: 8px;
}

.topContent {
  display: flex;
  justify-content: space-between;
}

.topContent h3 {
  color: var(--dash-sidebar-secondary);
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  margin-right: 10px;
}

.topContent h4 {
  color: var(--portal-gray-color);
  font-size: 15px;
  font-weight: 500;
  margin: 8px 0 0 0;
  padding: 0;
}

.titleWithNumber {
  display: flex;
}

.titleWithNumber span {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--dash-sidebar-tertiary);
  font-size: 10px;
  font-weight: 500;
  max-width: 100%;
  height: 20px;
  background: #D1E3FD;
  border-radius: 5px;
  margin-top: 3px;
  padding: 0 5px;
}

.rightContent {
  display: flex;
  align-items: center;
}

.downloadButton {
  display: flex;
  align-items: center;
  border: none;
  background: var(--portal-primary-color);
  width: 125px;
  height: 35px;
  border-radius: 5px;
  color: var(--white-color);
  font-size: 12px;
  font-weight: bold;
  padding: 10px 18px;
  cursor: pointer;
  transition: all .4s;
}

.downloadButton:hover {
  background: var(--portal-secondary-color);
  transition: all .4s;
}

.downloadButton i {
  width: 10px;
  height: 10px;
  margin-left: 5px;
}