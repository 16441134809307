@import url('../../assets/styles/variables.css');

.tableContainer {
  padding-top: 20px;
}

.tableCustom {
  background-color: var(--white-color);
  border-spacing: 0;
  width: 100%;
}

.tableCustom th,
.tableCustom td {
  max-width: 100%;
}

thead {
    display: block;
}

thead tr {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  align-items: center;
  padding: 4px 0;
}

.tbHeader {
  border-top: 1px solid #F5F5F5;
  border-bottom: 1px solid #F5F5F5;
}

tbody {
  display: block;
  overflow-y: auto;
  max-height: 70vh;
}

tbody tr {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  border-bottom: 1px solid #F5F5F5;
}

.tbHeader > tr > *,
tbody > tr > * {
  color: var(--bunting-color);
  font-size: 13px;
  margin: 0;
  padding: 13px 13px;
  text-align: left;
  font-weight: 500;
}

.tbHeader > tr > * {
  color: #b1b1b1;
  padding: 4px 10px;
}

th.tbAlignCenter,
td.tbAlignCenter,
th.details {
  text-align: center;
}

.tableTitle {
  background-color: #DCE4E8;
  width: 100%;
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
}

.tableTitle > * {
  font-size: 17px;
  color: var(--bunting-color);
  margin: 0;
  padding: 0;
}

.tbBtnDownload {
  display: flex;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--waterloo-color);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--waterloo-color);
}

.downloadButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px; 
  color: var(--bunting-color); 
  text-decoration: none;
  border: none;
  cursor: pointer;
}

.downloadButton svg {
  margin-left: 10px;
}

.cursor {
  cursor: pointer;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  width: 615px;
  padding: 0 50px;
  height: 100%;
  overflow: hidden;
}

.modalTop {
  display: flex;
  justify-content: space-between;
}

.modalTop h1 {
  font-family: var(--primary-font);
  font-size: 17px;
  font-weight: 700;
}

.modalTop .item {
  margin-top: 20px;
}

.modalTop .item p {
  font-family: var(--primary-font);
  font-size: 14px;
  font-weight: 600;
  color: var(--dark-gray);
  margin: 0;
}

.modalTop .item span {
  font-family: var(--primary-font);
  font-size: 12px;
  font-weight: 500;
  color: var(--dark-gray);
}

.whatsappButton {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--primary-font);
  font-size: 18px;
  font-weight: 400;
  width: 330px;
  background: #e2fdd1;
  border: 2px solid #458D1A;
  border-radius: 10px;
  padding: 10px;
  text-decoration: none;
}

.modalBottom {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
}

.whatsappButton:hover {
  color: #458D1A;
  cursor: pointer;
}

.whatsappButton img {
  width: 128px;
  height: 30px;
}

.modalRight {
  display: flex;
  flex-direction: column;
}

.modalRight h1 {
  margin-left: 10px;
}

.pending {
  display: inline-block;
  background: #FDE3D1;
  border-radius: 4px;
  color: #7D3300;
  font-size: 12px;  
  padding: 5px 10px;
  margin-bottom: 0;
}

.viewed {
  display: inline-block;
  padding: 5px 10px;
  margin-bottom: 0;
  background: #D1E3FD;
  border-radius: 4px;
  color: #00577D;
  font-size: 12px;
}

.generate {
  display: inline-block;
  padding: 5px 10px;
  margin-bottom: 0;
  background: #FEEFC3;
  border-radius: 4px;
  color: #8A6800;
  font-size: 12px;
}

.paid {
  display: inline-block;
  padding: 5px 10px;
  margin-bottom: 0;
  background: #E2FDD1;
  border-radius: 4px;
  color: #2F7D00;
  font-size: 12px;
}

.date {
  color: #757A96;
  font-size: 12px;
  margin-top: 5px;
}

.verticalSteps {
  margin-top: 0;
  list-style: none;
}

.pendingBullet,
.generatedBullet,
.viewedBullet,
.paidBullet {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: relative;
  left: -30px;
  top: 35px;
  z-index: 2;
}

.pendingBullet:before,
.generatedBullet:before,
.viewedBullet:before,
.paidBullet:before {
  content: "";
  display: inline-block;
  width: 1px;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  background-color: #c4c4c4;
  z-index: 1;
}

.paidBullet {
  background: #E2FDD1;
  border: 1px solid #458D1A;
}

.generatedBullet {
  background: #FEEFC3;
  border: 1px solid #8A6800;
}

.viewedBullet {
  background: #D1E3FD;
  border: 1px solid #277195;
}

.pendingBullet {
  background: #FDE3D1;
  border: 1px solid #7D3300;
}

.pendingBullet,
.generatedBullet,
.viewedBullet,
.paidBullet {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pendingBullet:before,
.generatedBullet:before,
.viewedBullet:before,
.paidBullet:before {
content: "";
display: inline-block;
width: 1px;
height: 100%;
position: absolute;
left: 50%;
top: 0;
transform: translateX(-50%);
background-color: #c4c4c4;
}

.generatedBullet:not(.last):before,
.viewedBullet:not(.last):before,
.paidBullet:not(.last):before {
  bottom: 0;
  top: 15px;
}

.pendingBullet:after,
.generatedBullet:after,
.viewedBullet:after,
.paidBullet:after {
content: "";
display: inline-block;
width: 1px;
height: 100px;
position: absolute;
left: 50%;
top: 100%;
transform: translateX(-50%);
background-color: #757A96;
}

.viewedBullet:after {
  height: 85px;
}

.pendingBullet:after, .pendingBullet:before {
  height: 0 !important;
  display: none;
}

.details img {
  cursor: pointer;
  width: 25px;
  margin: 0 auto;
  padding-left: 20px;
}

/* tbody > tr > *:nth-child(n+4),
.tbHeader > tr > *:nth-child(n+4) {
  text-align: center;
} */